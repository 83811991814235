import React, { useEffect, useState, useRef } from 'react';
import _ from 'lodash';
import CssBaseline from '@mui/material/CssBaseline';
import { Autocomplete, Box, Divider, Link } from '@mui/material';
import {
	Grid,
	Typography,
	TextField,
	Button,
	Stack,
	FormControlLabel,
	FormControl,
	InputLabel,
	Select,
	OutlinedInput,
	InputAdornment,
	CircularProgress,
	ListItemText
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import { Paper, MenuItem, Checkbox } from '@mui/material';
import { create, update, getListPost, post } from '../../services/services';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomizedAlertBar from '../../components/customAlertBar';
import { useAuth } from '../../context/AuthProvider';
import { IOSSwitch, IOSSwitchColored } from '../../components/IosSwitchButton';
import { GridActionsCellItem } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { surgeryType, vitalSigns, wellBeing, woundInputs } from '../../helpers/constants';
import SurgeryTemplateDialog from '../../components/dailogs/SurgeryTemplateDialog';
import { useLanguage } from '../../context/LanguageProvider';
import KpisDialog from '../../components/dailogs/kpisDialog';

const Template = (props) => {
	const navigate = useNavigate();
	const location = useLocation();
	const { getLangString } = useLanguage();
	const pageData = location.state?.params;
	const alertRef = useRef(null);
	const templateRef = useRef(null);

	const { tokenData, profile } = useAuth();
	console.log('profile', profile);
	const isAdmin = tokenData?.role === 'admin';
	const [submitType, setSubmitType] = useState('add');

	const [patient_clinic_id, set_patient_clinic_id] = useState('');
	const [procedure_patient_id, set_procedure_patient_id] = useState('');
	const [procedure_model_type, set_procedure_model_type] = useState('face');
	const [procedure_code, set_procedure_code] = useState('');
	const [procedure_description, set_procedure_description] = useState('');

	const [procedure_date_time, set_procedure_date_time] = useState('');
	const [procedure_type, set_procedure_type] = useState('');
	const [procedure_is_active, set_procedure_is_active] = useState(true);
	const [procedure_doctors_roles, set_procedure_doctors_roles] = useState([]);

	const [monitor_period_from, set_monitor_period_from] = useState('');
	const [monitor_period_to, set_monitor_period_to] = useState('');
	const [temperature, set_temperature] = useState({
		enable: '1',
		frequency: '1',
		unit: 'Celsius'
	});

	const [procedure_code_obj, set_procedure_code_obj] = useState(null);
	const [all_cpt_codes, set_all_cpt_codes] = useState([]);

	/// KPIS
	const [open_kpis_dailog, set_open_kpis_dailog] = useState(false);
	const [kpi_type, set_kpi_type] = useState('');

	const [vital_signs, set_vital_signs] = useState(vitalSigns);

	const [check_vital_signs, set_check_vital_signs] = useState(true);
	const vitalSignsEnabled = Object.entries(vital_signs).filter(
		([key, item]) => item.enable === '1' && key !== 'enable_all'
	).length;

	// wound_inputs
	const [wound_inputs, set_wound_inputs] = useState(woundInputs);
	const [check_wound_inputs, set_check_wound_inputs] = useState(true);
	const woundInputsEnabled = Object.entries(wound_inputs).filter(
		([key, item]) => item.enable === '1' && key !== 'enable_all'
	).length;

	// well_being
	const [well_being, set_well_being] = useState(wellBeing);

	const [check_well_being, set_check_well_being] = useState(true);
	const wellBeingEnabled = Object.entries(well_being).filter(
		([key, item]) => item.enable === '1' && key !== 'enable_all'
	).length;

	const [add_medication_id, set_add_medication_id] = useState('');
	const [add_medication_name, set_add_medication_name] = useState('');
	const [add_medication_dose, set_add_medication_dose] = useState('');
	const [add_medication_daily_intake, set_add_medication_daily_intake] = useState('');

	const [medication_obj, set_medication_obj] = useState([]);
	const [medications_list, set_medications_list] = useState([]);

	const [loading, set_loading] = useState(true);
	const [main_surgeon, set_main_surgeon] = useState({});
	const [selected_staff_doctors_names, set_selected_staff_doctors_names] = useState([]);

	const [surgery_templates, set_surgery_templates] = useState([]);
	const [selected_surgery_template, set_selected_surgery_template] = useState({});
	const [surgery_template_name, set_surgery_template_name] = useState('');

	const [clinics_array, set_clinics_array] = useState([]);
	const [clinic_surgeons_list, set_clinic_surgeons_list] = useState([]);

	const [clinic_patients_list, set_clinic_patients_list] = useState([]);

	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useForm();

	const setSurgeryTemplateName = (params) => {
		set_surgery_template_name(params);
	};

	const resetState = () => {
		set_patient_clinic_id('');
		set_procedure_patient_id('');
		set_procedure_date_time('');
		set_procedure_type('');
		set_procedure_model_type('face');
		set_procedure_code('');
		set_procedure_description('');
		set_procedure_doctors_roles([]);
		set_vital_signs(vitalSigns);
		set_wound_inputs(woundInputs);
		set_well_being(wellBeing);
		set_medication_obj([]);
		set_main_surgeon({});
		set_selected_staff_doctors_names([]);
		set_selected_surgery_template({});
	};

	const values = {
		patient_clinic_id,
		procedure_patient_id,
		procedure_date_time,
		procedure_type,
		procedure_doctors_roles,
		procedure_code_obj,
		procedure_code,
		procedure_description,
		procedure_model_type,
		procedure_is_active: procedure_is_active ? 1 : 0,
		procedure_treatment_plan: {
			vital_signs,
			wound_inputs,
			well_being,
			medication_obj
		},
		// for testing
		medications_list,
		surgery_template_name
	};

	const setNewMedication = () => {
		if (
			add_medication_id === '' ||
			add_medication_name === '' ||
			add_medication_dose === '' ||
			add_medication_daily_intake === ''
		) {
			alertRef.current.handleClick({
				message: 'ALL MEDICATION FIELDS ARE REQUIRED',
				severity: 'error'
			});
			return;
		}
		let medication = {
			med_id: add_medication_id,
			medication_name: add_medication_name,
			medication_dose: add_medication_dose,
			ma_standard_frequency: add_medication_daily_intake
		};
		set_medication_obj((oldArray) => [...oldArray, medication]);
		set_add_medication_id('');
		set_add_medication_name('');
		set_add_medication_dose('');
		set_add_medication_daily_intake('');
	};

	const deleteUser = (params) => () => {
		set_medication_obj(
			_.filter(medication_obj, function (o) {
				return o.med_id !== params.id;
			})
		);
	};

	const editUser = (params) => () => {
		set_medication_obj(
			_.filter(medication_obj, function (o) {
				return o.med_id !== params.id;
			})
		);
		set_add_medication_id(params.row.med_id);
		set_add_medication_name(params.row.medication_name);
		set_add_medication_dose(params.row.medication_dose);
		set_add_medication_daily_intake(params.row.ma_standard_frequency);
	};

	const newMedicationColumns = [
		{ field: 'ma_id', headerName: getLangString('id'), width: 40 },
		{
			field: 'medication_name',
			headerName: getLangString('medication_name'),
			flex: 1,
			minWidth: 100
		},
		{ field: 'medication_dose', headerName: getLangString('dose'), width: 130 },
		{
			field: 'ma_standard_frequency',
			headerName: getLangString('standard_frequency'),
			type: 'number',
			flex: 1,
			minWidth: 50
		},
		{
			field: 'actions',
			headerName: getLangString('action'),
			type: 'actions',
			minWidth: 120,
			getActions: (params) => [
				<GridActionsCellItem
					icon={<ModeEditOutlineOutlinedIcon />}
					label="Edit"
					onClick={editUser(params)}
				/>,
				<GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={deleteUser(params)} />
			]
		}
	];

	const dailyIntakeFrequency = [
		{
			value: 'DAILY',
			label: 'DAILY'
		},
		{
			value: 'qAM',
			label: 'qAM'
		},
		{
			value: 'BID',
			label: 'BID'
		},
		{
			value: 'TID',
			label: 'TID'
		},
		{
			value: 'QID',
			label: 'QID'
		},
		{
			value: 'Before Meals',
			label: 'Before Meals'
		},
		{
			value: 'After Meals',
			label: 'After Meals'
		},
		{
			value: 'q1h',
			label: 'q1h'
		},
		{
			value: 'q2h',
			label: 'q2h'
		},
		{
			value: 'q3h',
			label: 'q3h'
		},
		{
			value: 'q4h',
			label: 'q4h'
		},
		{
			value: 'q6h',
			label: 'q6h'
		},
		{
			value: 'q8h',
			label: 'q8h'
		},
		{
			value: 'q12h',
			label: 'q12h'
		},
		{
			value: 'HS',
			label: 'HS'
		}
	];

	useEffect(() => {
		if (vitalSignsEnabled > 0) set_check_vital_signs(true);
		else set_check_vital_signs(false);
	}, [vitalSignsEnabled]);

	useEffect(() => {
		if (woundInputsEnabled > 0) set_check_wound_inputs(true);
		else set_check_wound_inputs(false);
	}, [woundInputsEnabled]);

	useEffect(() => {
		if (wellBeingEnabled > 0) set_check_well_being(true);
		else set_check_well_being(false);
	}, [wellBeingEnabled]);

	useEffect(() => {
		const fetchData = async () => {
			const urlParams1 = { resource: 'procedure', action: 'getmedications' };
			const data1 = await getListPost(urlParams1);
			set_medications_list(data1.data);

			const urlParams2 = { resource: 'clinic', action: 'getclinics' };
			let data2 = await getListPost(urlParams2);
			set_clinics_array(data2.data);

			const urlParams3 = { resource: 'procedure', action: 'getCPTcode' };
			let data3 = await post(urlParams3);
			set_all_cpt_codes(data3.data);

			set_loading(false);
		};
		fetchData().catch(console.error);
	}, []);

	useEffect(() => {
		const fetchData = async () => {
			set_surgery_templates([]);
			const urlParams = { resource: 'procedure', action: 'GetAllTemplates' };
			let data = await getListPost(urlParams, { clinic_id: 1 });
			set_surgery_templates(data.data);
		};
		fetchData().catch(console.error);
	}, [patient_clinic_id]);

	useEffect(() => {
		if (pageData) {
			set_selected_surgery_template(pageData);
			const data = JSON.parse(pageData?.template_configuration);
			if (data.procedure_treatment_plan.vital_signs)
				set_vital_signs(data.procedure_treatment_plan.vital_signs);
			if (data.procedure_treatment_plan.wound_inputs)
				set_wound_inputs(data.procedure_treatment_plan.wound_inputs);
			if (data.procedure_treatment_plan.well_being)
				set_well_being(data.procedure_treatment_plan.well_being);
			set_medication_obj(data.procedure_treatment_plan.medication_obj);
			set_procedure_type(data.procedure_type);
			set_procedure_model_type(data.procedure_model_type);
			set_procedure_code_obj(data.procedure_code_obj);
			set_procedure_code(data.procedure_code);
			set_procedure_description(data.procedure_description);
		}
	}, [selected_surgery_template]);

	useEffect(() => {
		if (pageData) {
			set_patient_clinic_id(pageData.clinic_id);
			set_procedure_date_time(pageData?.performedDateTime?.split('T')[0] || '');
		}
	}, [patient_clinic_id]);

	useEffect(() => {
		if (pageData) set_procedure_patient_id(pageData?.patient_id || '');
	}, [clinic_patients_list]);

	useEffect(() => {
		if (!isAdmin) {
			set_patient_clinic_id(profile.clinic_id);
		}
	}, []);

	useEffect(() => {
		// logging on every change
		console.log('FROM useEffect');
		console.log(values);
	});

	useEffect(() => {
		if (_.some(['lip', 'cleft'], (el) => _.includes(procedure_description.toLowerCase(), el)))
			set_procedure_model_type('face');
		else set_procedure_model_type('wound');
	}, [procedure_description]);

	const checkCPTCode = () => {
		if (procedure_code === '' || procedure_code === null) {
			alertRef.current.handleClick({
				message: 'CPT Code is required',
				severity: 'error'
			});
			return false;
		}
		return true;
	};

	const OnSubmitSurgeryTemplate = async () => {
		try {
			const urlParams = { resource: 'procedure', action: 'AddProcedureTemplate' };
			console.log('clinci', { clinic_id: patient_clinic_id });
			const response = await create(urlParams, {
				clinic_id: patient_clinic_id,
				template_name: surgery_template_name,
				template_configuration: {
					procedure_type,
					procedure_code_obj,
					procedure_code,
					procedure_description,
					procedure_doctors_roles,
					procedure_model_type,
					procedure_treatment_plan: {
						vital_signs: check_vital_signs ? vital_signs : null,
						wound_inputs: check_wound_inputs ? wound_inputs : null,
						well_being: check_well_being ? well_being : null,
						medication_obj: medication_obj.map((element, index) => {
							element.ma_start_date = '';
							element.ma_end_date = '';
							return element;
						})
					}
				},
				created_by: isAdmin ? '0' : `${profile?.profile_id}`
			});
			console.log(response);
			if (response.status === 200 || response.status === 201) {
				alertRef.current.handleClick({
					message: response.data.data.toUpperCase(),
					severity: 'success'
				});
				setTimeout(() => {
					navigate(-1);
				}, 3000);
			}
			// resetState();
		} catch (err) {
			alertRef.current.handleClick({
				message: err !== '' ? err.toString().split(':')[1].toUpperCase() : 'Failed',
				severity: 'error'
			});
		}
	};

	const OnSubmitUpdateSurgeryTemplate = async () => {
		try {
			if (!checkCPTCode()) return;
			const urlParams = { resource: 'procedure', action: 'UpdateProcedureTemplate' };
			const response = await create(urlParams, {
				id: selected_surgery_template.id,
				template_configuration: {
					procedure_type,
					procedure_code_obj,
					procedure_code,
					procedure_description,
					procedure_doctors_roles,
					procedure_model_type,
					procedure_treatment_plan: {
						vital_signs: check_vital_signs ? vital_signs : null,
						wound_inputs: check_wound_inputs ? wound_inputs : null,
						well_being: check_well_being ? well_being : null,
						medication_obj: medication_obj.map((element, index) => {
							element.ma_start_date = '';
							element.ma_end_date = '';
							return element;
						})
					}
				}
			});
			console.log(response);
			if (response.status === 200 || response.status === 201) {
				alertRef.current.handleClick({
					message: response.data.data.toUpperCase(),
					severity: 'success'
				});
				setTimeout(() => {
					navigate(-1);
				}, 3000);
			}
			// resetState();
		} catch (err) {
			alertRef.current.handleClick({
				message: err !== '' ? err.toString().split(':')[1].toUpperCase() : 'Failed',
				severity: 'error'
			});
		}
	};

	const onSubmit = async (e) => {
		try {
			console.log('submitType:', submitType); // Check the submission type (add/update)

			if (submitType === 'add') {
				if (!checkCPTCode()) return;
				templateRef.current.handleClickOpen({});
			} else if (submitType === 'update') {
				await OnSubmitUpdateSurgeryTemplate();
			}
		} catch (err) {
			alertRef.current.handleClick({
				message: err !== '' ? err.toString().split(':')[1].toUpperCase() : 'Failed',
				severity: 'error'
			});
		}
	};

	const updateKpi = (kpi_type, key, values) => {
		kpi_type === 'vital_signs'
			? set_vital_signs((prevState) => ({
					...prevState,
					[key]: {
						...prevState[key],
						...values
					}
			  }))
			: kpi_type === 'wound_inputs'
			? set_wound_inputs((prevState) => ({
					...prevState,
					[key]: {
						...prevState[key],
						...values
					}
			  }))
			: set_well_being((prevState) => ({
					...prevState,
					[key]: {
						...prevState[key],
						...values
					}
			  }));
	};

	const theme = createTheme();

	return loading ? (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'center',
				height: '100%',
				width: '100%'
			}}>
			<CircularProgress />
		</Box>
	) : (
		<ThemeProvider theme={theme}>
			<CustomizedAlertBar ref={alertRef} />
			<SurgeryTemplateDialog
				ref={templateRef}
				template_name={surgery_template_name}
				set_template_name={setSurgeryTemplateName}
				submit={OnSubmitSurgeryTemplate}
			/>
			<KpisDialog
				open={open_kpis_dailog}
				setOpen={set_open_kpis_dailog}
				kpiType={kpi_type}
				// initialKpis={
				// 	kpi_type === 'vital_signs'
				// 		? initial_vital_signs
				// 		: kpi_type === 'wound_inputs'
				// 		? initial_wound_inputs
				// 		: kpi_type === 'well_being'
				// 		? initial_well_being
				// 		: {}
				// }
				kpis={
					kpi_type === 'vital_signs'
						? vital_signs
						: kpi_type === 'wound_inputs'
						? wound_inputs
						: kpi_type === 'well_being'
						? well_being
						: {}
				}
				setKpis={
					kpi_type === 'vital_signs'
						? set_vital_signs
						: kpi_type === 'wound_inputs'
						? set_wound_inputs
						: kpi_type === 'well_being'
						? set_well_being
						: {}
				}
				updateKpis={updateKpi}
			/>
			<CssBaseline />
			<Container maxWidth="xl" sx={{ mt: 4 }}>
				<Paper
					variant="outlined"
					sx={{
						borderRadius: 2,
						boxShadow: 2,
						borderColor: '#1db14c'
					}}>
					<Stack
						direction="row"
						spacing={1}
						sx={{
							display: 'flex',
							justifyContent: 'space-between'
						}}>
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'flex-start'
							}}>
							<Toolbar>
								<Typography variant="h6" align="left" color="#1db14c" sx={{}}>
									{pageData
										? getLangString('edit_new_template')
										: getLangString('add_new_template')}
								</Typography>
							</Toolbar>
						</Box>
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'flex-end',
								pr: 2
							}}></Box>
					</Stack>
				</Paper>
			</Container>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Container component="main" maxWidth="xl">
					<Paper
						variant="outlined"
						sx={{
							borderColor: '#1db14c',
							borderRadius: 2,
							boxShadow: 2,
							my: { xs: 3, md: 2 },
							p: { xs: 2, md: 3 }
						}}>
						<React.Fragment>
							<Stack direction="row" justifyContent="space-between" sx={{ mb: 2 }}>
								<Typography variant="h6" gutterBottom>
									{getLangString('surgery_details')}
								</Typography>

								{patient_clinic_id !== '' && (
									<Stack direction="row" justifyContent="flex-end" spacing={1}>
										{/* <FormControl sx={{ minWidth: 120 }} size="small">
											<InputLabel id="demo-simple-select-helper-label">Templates</InputLabel>
											<Select
												labelId="demo-simple-select-helper-label"
												id="demo-simple-select-helper"
												value={selected_surgery_template?.id || ''}
												label="Templates"
												onChange={(e) => {
													set_selected_surgery_template(
														_.filter(surgery_templates, function (o) {
															return o.id === e.target.value;
														})[0]
													);
												}}>
												{surgery_templates.map((option) => (
													<MenuItem key={option.id} value={option.id}>
														{option.template_name}
													</MenuItem>
												))}
											</Select>
										</FormControl> */}

										{/* {_.isEmpty(selected_surgery_template) ? (
										<Button
											variant="contained"
											color="success"
											type="submit"
											onClick={() => {
												templateRef.current.handleClickOpen({});
											}}>
											Save Template
										</Button>
									) : (
										<>
											<Button
												variant="contained"
												// sx={{ backgroundColor: 'orange' }}
												color="success"
												type="submit"
												onClick={() => {
													OnSubmitUpdateSurgeryTemplate();
												}}>
												Update Template
											</Button>
										</>
									)} */}
									</Stack>
								)}
							</Stack>
							<Grid container spacing={3}>
								{isAdmin && (
									<Grid item xs={12} sm={12}>
										<TextField
											select
											id="clinic"
											name="clinic"
											label={getLangString('clinic_name')}
											value={patient_clinic_id}
											fullWidth
											required
											variant="outlined"
											disabled={!isAdmin}
											onChange={(e) => {
												if (!isAdmin) return;
												set_patient_clinic_id(e.target.value);
												set_main_surgeon({});
												set_selected_staff_doctors_names([]);
											}}>
											{clinics_array.map((option) => (
												<MenuItem key={option.clinic_id} value={option.clinic_id}>
													{option.clinic_name}
												</MenuItem>
											))}
										</TextField>
									</Grid>
								)}

								<Grid item xs={12} sm={3}>
									<FormControl fullWidth required>
										<InputLabel id="demo-multiple-checkbox-label">Surgery Type</InputLabel>
										<Select
											id="surgeryType"
											name="surgeryType"
											label={getLangString('surgery_type')}
											variant="outlined"
											fullWidth
											required
											value={procedure_type}
											onChange={(e) => {
												set_procedure_type(e.target.value);
											}}>
											{surgeryType.map((option) => (
												<MenuItem key={option.value} value={option.value}>
													{option.label}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid>

								<Grid item xs={12} sm={3}>
									<FormControl fullWidth required>
										<Autocomplete
											fullWidth
											disablePortal
											id="procedureCode"
											name="procedureCode"
											required
											options={all_cpt_codes}
											getOptionLabel={(option) => {
												return option.cpt_code;
											}}
											renderInput={(params) => (
												<TextField fullWidth {...params} label={getLangString('surgery_code')} />
											)}
											value={procedure_code_obj}
											isOptionEqualToValue={(option, value) => {
												return option?.id === value?.id;
											}}
											onChange={(event, newValue, reason) => {
												if (reason === 'clear') {
													set_procedure_code_obj(null);
													set_procedure_code('');
													set_procedure_description('');
												}
												set_procedure_code_obj(newValue);
												set_procedure_code(newValue.cpt_code);
												set_procedure_description(newValue.cpt_code_description);
											}}
										/>
									</FormControl>
								</Grid>

								<Grid item xs={12} sm={6}>
									<TextField
										required
										id="surgeryDescription "
										name="surgeryDescription"
										label={getLangString('surgery_description')}
										value={procedure_description}
										fullWidth
										variant="outlined"
										onChange={(e) => {
											set_procedure_description(e.target.value);
										}}
									/>
								</Grid>
							</Grid>
						</React.Fragment>
					</Paper>
				</Container>

				<Container component="main" maxWidth="xl" sx={{ mb: 1 }}>
					<Paper
						variant="outlined"
						sx={{
							borderColor: '#1db14c',
							borderRadius: 2,
							boxShadow: 2,
							// my: { xs: 3, md: 2 },
							p: { xs: 2, md: 3 }
						}}>
						<React.Fragment>
							{/* <Grid item xs={6} xl={6}> */}
							<Stack direction="row" justifyContent="space-between">
								<Typography variant="h6" gutterBottom>
									{getLangString('monitoring_plan')}
								</Typography>
							</Stack>

							<Grid container spacing={3}>
								<Grid
									item
									xs={12}
									md={2.5}
									container
									alignItems="flex-end"
									justifyContent="flex-start">
									<Stack direction="row" alignItems="center">
										<IOSSwitch
											checked={check_vital_signs}
											onChange={(event) => {
												set_check_vital_signs(event.target.checked);
												set_open_kpis_dailog(event.target.checked);
												set_kpi_type('vital_signs');
											}}
											inputProps={{ 'aria-label': 'controlled' }}
											sx={{ mr: 1 }}
										/>
										<Stack direction="column" alignItems="end">
											<Link
												component="button"
												variant="body2"
												onClick={() => {
													set_open_kpis_dailog(true);
													set_kpi_type('vital_signs');
												}}
												sx={{
													fontFamily: 'Roboto',
													fontSize: '16px',
													fontWeight: 400,
													lineHeight: '19px',
													textAlign: 'left',
													mt: 2
												}}
												noWrap>
												{getLangString('vital_signs')}
											</Link>
											{vitalSignsEnabled > 0 ? (
												<Typography
													sx={{
														width: '24px',
														height: '16px',
														fontFamily: 'Roboto',
														fontStyle: 'normal',
														fontWeight: 400,
														fontSize: '11px',
														lineHeight: '17px',
														letterSpacing: '0.106132px',
														color: 'rgba(0, 0, 0, 0.6)'
													}}>
													{/* {check_vital_signs &&
													`${vitalSignsEnabled}/${Object.values(vital_signs).length}`} */}
												</Typography>
											) : (
												<Typography
													sx={{
														width: '24px',
														height: '16px',
														fontFamily: 'Roboto',
														fontStyle: 'normal',
														fontWeight: 400,
														fontSize: '11px',
														lineHeight: '17px',
														letterSpacing: '0.106132px',
														color: 'rgba(0, 0, 0, 0.6)'
													}}>
													{` `}
												</Typography>
											)}
										</Stack>
									</Stack>
								</Grid>
								<Grid
									item
									xs={12}
									md={2.5}
									container
									alignItems="flex-end"
									justifyContent="flex-start">
									<Stack direction="row" alignItems="center">
										<IOSSwitch
											checked={check_wound_inputs}
											onChange={(event) => {
												// set_check_wound_inputs(event.target.checked);
												set_open_kpis_dailog(event.target.checked);
												set_kpi_type('wound_inputs');
											}}
											inputProps={{ 'aria-label': 'controlled' }}
											sx={{ mr: 1 }}
										/>
										<Stack direction="column" alignItems="end">
											<Link
												component="button"
												variant="body2"
												noWrap
												onClick={(event) => {
													set_open_kpis_dailog(true);
													set_kpi_type('wound_inputs');
												}}
												sx={{
													fontFamily: 'Roboto',
													fontSize: '16px',
													fontWeight: 400,
													lineHeight: '19px',
													textAlign: 'left',
													mt: 2
												}}>
												{getLangString('wound_inputs')}
											</Link>
											{woundInputsEnabled > 0 ? (
												<Typography
													sx={{
														width: '24px',
														height: '16px',
														fontFamily: 'Roboto',
														fontStyle: 'normal',
														fontWeight: 400,
														fontSize: '11px',
														lineHeight: '17px',
														letterSpacing: '0.106132px',
														color: 'rgba(0, 0, 0, 0.6)'
													}}>
													{/* {check_wound_inputs &&
													`${woundInputsEnabled}/${Object.values(wound_inputs).length}`} */}
												</Typography>
											) : (
												<Typography
													sx={{
														width: '24px',
														height: '16px',
														fontFamily: 'Roboto',
														fontStyle: 'normal',
														fontWeight: 400,
														fontSize: '11px',
														lineHeight: '17px',
														letterSpacing: '0.106132px',
														color: 'rgba(0, 0, 0, 0.6)'
													}}>
													{` `}
												</Typography>
											)}
										</Stack>
									</Stack>
								</Grid>
								<Grid
									item
									xs={12}
									md={2.5}
									container
									alignItems="flex-end"
									justifyContent="flex-start">
									<Stack direction="row" alignItems="center">
										<IOSSwitch
											checked={check_well_being}
											onChange={(event) => {
												set_check_well_being(event.target.checked);
												set_open_kpis_dailog(event.target.checked);
												set_kpi_type('well_being');
											}}
											inputProps={{ 'aria-label': 'controlled' }}
											sx={{ mr: 1 }}
										/>
										<Stack direction="column" alignItems="end">
											<Link
												component="button"
												variant="body2"
												onClick={() => {
													set_open_kpis_dailog(true);
													set_kpi_type('well_being');
												}}
												sx={{
													fontFamily: 'Roboto',
													fontSize: '16px',
													fontWeight: 400,
													lineHeight: '19px',
													textAlign: 'left',
													mt: 2
												}}>
												{getLangString('well_being')}
											</Link>
											{wellBeingEnabled > 0 ? (
												<Typography
													disabled={wellBeingEnabled > 0}
													sx={{
														width: '24px',
														height: '16px',
														fontFamily: 'Roboto',
														fontStyle: 'normal',
														fontWeight: 400,
														fontSize: '11px',
														lineHeight: '17px',
														letterSpacing: '0.106132px',
														color: 'rgba(0, 0, 0, 0.6)'
													}}>
													{/* {check_well_being &&
													`${wellBeingEnabled}/${Object.values(well_being).length}`} */}
												</Typography>
											) : (
												<Typography
													sx={{
														width: '24px',
														height: '16px',
														fontFamily: 'Roboto',
														fontStyle: 'normal',
														fontWeight: 400,
														fontSize: '11px',
														lineHeight: '17px',
														letterSpacing: '0.106132px',
														color: 'rgba(0, 0, 0, 0.6)'
													}}>
													{` `}
												</Typography>
											)}
										</Stack>
									</Stack>
								</Grid>
							</Grid>

							<Divider sx={{ my: 3 }} variant="middle" />

							<Typography variant="h6" gutterBottom>
								{getLangString('prescription_medications')}
							</Typography>
							<Grid container spacing={3}>
								<Grid item xs={12} sm={12}>
									<TextField
										select
										id="medicationName"
										name="medicationName"
										label={getLangString('medication_name')}
										fullWidth
										variant="outlined"
										value={add_medication_name}
										onChange={(event) => {
											set_add_medication_name(event.target.value);
											let obj = _.find(medications_list, {
												medication_name: event.target.value
											});
											set_add_medication_id(obj.medication_id);
											set_add_medication_dose(obj.medication_dose);
										}}>
										{medications_list.map((option) => {
											return (
												<MenuItem key={option.medication_id} value={option.medication_name}>
													{option.medication_name}
												</MenuItem>
											);
										})}
									</TextField>
								</Grid>
								<Grid item xs={12} sm={6}>
									<TextField
										id="dose"
										name="dose"
										label={getLangString('dose')}
										fullWidth
										variant="outlined"
										value={add_medication_dose}
										onChange={(event) => {
											set_add_medication_dose(event.target.value);
										}}
										InputProps={{
											endAdornment: <InputAdornment position="start">mg</InputAdornment>
										}}
										InputLabelProps={{
											shrink: true
										}}>
										{' '}
									</TextField>
								</Grid>
								<Grid item xs={12} sm={6}>
									<TextField
										select
										id="dailyIntakeFrequency"
										name="dailyIntakeFrequency"
										label={getLangString('standard_frequency')}
										fullWidth
										value={add_medication_daily_intake}
										variant="outlined"
										onChange={(event) => {
											set_add_medication_daily_intake(event.target.value);
										}}>
										{dailyIntakeFrequency.map((option) => (
											<MenuItem key={option.value} value={option.value}>
												{option.label}
											</MenuItem>
										))}
									</TextField>
								</Grid>
								<Grid item xs={12} xl={12}>
									<Box textAlign="left">
										<Button variant="contained" color="success" onClick={setNewMedication}>
											Add
										</Button>
									</Box>
								</Grid>

								<Divider sx={{ my: 3 }} variant="middle" />

								<Grid item xs={12} xl={12}>
									<Typography variant="h6" gutterBottom>
										{getLangString('medication_plan')}
									</Typography>
								</Grid>
								<Box sx={{ mt: 4, mx: 2, height: 400, width: '100%' }}>
									<DataGrid
										columns={newMedicationColumns}
										rows={medication_obj}
										getRowId={(row) => row.med_id}
										pageSize={5}
										rowsPerPageOptions={[5]}
										checkboxSelection
										disableSelectionOnClick
									/>
								</Box>

								<Container sx={{ pt: 3, px: 2, width: '100%' }} maxWidth="xl" disableGutters>
									<Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
										<Box>
											<Button
												variant="outlined"
												color="success"
												type="button"
												onClick={() => {
													navigate(-1);
												}}>
												{getLangString('cancel')}
											</Button>
										</Box>
										<Box>
											{_.isEmpty(selected_surgery_template) ? (
												<Button
													variant="contained"
													color="success"
													type="submit"
													onClick={() => {
														setSubmitType('add');
													}}>
													{getLangString('save_template')}
												</Button>
											) : (
												<>
													<Button
														variant="contained"
														color="success"
														type="submit"
														onClick={() => setSubmitType('update')}>
														{getLangString('update_template')}
													</Button>
												</>
											)}
										</Box>
									</Box>
								</Container>
							</Grid>
						</React.Fragment>
					</Paper>
				</Container>
			</form>
		</ThemeProvider>
	);
};

export default Template;
