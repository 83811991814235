import React, { useEffect, useState, useRef, createContext } from 'react';
import _, { isEqual } from 'lodash';
import CssBaseline from '@mui/material/CssBaseline';
import { Autocomplete, Box, Divider, Link } from '@mui/material';
import {
	Grid,
	Typography,
	TextField,
	Button,
	Stack,
	FormControlLabel,
	FormControl,
	InputLabel,
	Select,
	OutlinedInput,
	InputAdornment,
	CircularProgress,
	ListItemText
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import { Paper, MenuItem, Checkbox } from '@mui/material';
import { create, getListPost, post, update } from '../../services/services';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { dailyIntakeFrequency } from './constantArrays';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomizedAlertBar from '../../components/customAlertBar';
import { useAuth } from '../../context/AuthProvider';
import { IOSSwitch, IOSSwitchColored } from '../../components/IosSwitchButton';
import { GridActionsCellItem } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { surgeryType, vitalSigns, wellBeing, woundInputs } from '../../helpers/constants';
import { useLanguage } from '../../context/LanguageProvider';
import { styled, lighten, darken } from '@mui/system';
import AddMedicationDialog from '../../components/dailogs/addMedicationDialog';
import KpisDialog from '../../components/dailogs/kpisDialog';
import AddCptCodeDailog from '../../components/dailogs/addCptCodeDailog';

const EditSurgery = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const { getLangString } = useLanguage();
	const data = location.state.params;
	// console.log('data ------> ', data);
	const alertRef = useRef(null);

	const { tokenData, profile } = useAuth();
	const isAdmin = tokenData?.role === 'admin';
	const isOrgAdmin = profile?.profile?.access_level === 'orgAdmin';

	const [procedure_id, set_procedure_id] = useState(data.procedure_id);
	const [patient_clinic_id, set_patient_clinic_id] = useState(data.patient_clinic_id);
	const [procedure_patient_id, set_procedure_patient_id] = useState(
		data.procedure_patient_id || data.patient_id
	);
	const [procedure_date_time, set_procedure_date_time] = useState(
		data.procedure_date_time?.split('T')[0]
	);
	const [procedure_type, set_procedure_type] = useState(data.procedure_type);
	const [procedure_model_type, set_procedure_model_type] = useState(
		data?.procedure_model_type || 'wound'
	);
	const [procedure_code, set_procedure_code] = useState(data?.procedure_code || '');
	const [procedure_code_obj, set_procedure_code_obj] = useState({});
	const [procedure_description, set_procedure_description] = useState(data?.procedure_description);
	const [procedure_is_active, set_procedure_is_active] = useState(
		data.procedure_is_active === 1 ? true : false
	);
	const [procedure_doctors_roles, set_procedure_doctors_roles] = useState([]);
	const [procedure_surgeons_list, set_procedure_surgeons_list] = useState([]);

	const [main_surgeon, set_main_surgeon] = useState({});
	const [clinic_surgeons_list, set_clinic_surgeons_list] = useState([]);
	const [selected_staff_doctors_names, set_selected_staff_doctors_names] = useState([]);

	const [monitor_period_from, set_monitor_period_from] = useState('');
	const [monitor_period_to, set_monitor_period_to] = useState('');

	const [all_cpt_codes, set_all_cpt_codes] = useState([]);

	/// KPIS
	const [open_kpis_dailog, set_open_kpis_dailog] = useState(false);
	const [kpi_type, set_kpi_type] = useState('');

	const [vital_signs, set_vital_signs] = useState(vitalSigns);

	const [check_vital_signs, set_check_vital_signs] = useState(true);
	const vitalSignsEnabled = Object.entries(vital_signs).filter(
		([key, item]) => item.enable === '1' && key !== 'enable_all'
	).length;

	// wound_inputs
	const [wound_inputs, set_wound_inputs] = useState(woundInputs);
	const [check_wound_inputs, set_check_wound_inputs] = useState(true);
	const woundInputsEnabled = Object.entries(wound_inputs).filter(
		([key, item]) => item.enable === '1' && key !== 'enable_all'
	).length;

	// well_being
	const [well_being, set_well_being] = useState(wellBeing);

	const [check_well_being, set_check_well_being] = useState(true);
	const wellBeingEnabled = Object.entries(well_being).filter(
		([key, item]) => item.enable === '1' && key !== 'enable_all'
	).length;

	const [add_medication_id, set_add_medication_id] = useState('');
	const [selected_medication_option, set_selected_medication_option] = useState(null);
	const [add_medication_name, set_add_medication_name] = useState(null);
	const [add_medication_dose, set_add_medication_dose] = useState('');
	const [add_medication_daily_intake, set_add_medication_daily_intake] = useState('');
	const [add_medication_start_date, set_add_medication_start_date] = useState('');
	const [add_medication_end_date, set_add_medication_end_date] = useState('');

	const [previous_medications_obj, set_previous_medications_obj] = useState([]); // table
	const [new_medication_obj, set_new_medication_obj] = useState([]);

	const [medications_list, set_medications_list] = useState([]);
	const [loading, set_loading] = useState(true);
	const [surgeon, set_surgeon] = useState({});
	const [loaded, set_loaded] = useState(false);

	const [openMedicationDailog, setOpenMedicationDailog] = useState(false);
	const [openCptCodeDailog, setOpenCptCodeDailog] = useState(false);
	const [update_medications, set_update_medications] = useState(false);

	const [initial_vital_signs, set_initial_vital_signs] = useState({});
	const [initial_wound_inputs, set_initial_wound_inputs] = useState({});
	const [initial_well_being, set_initial_well_being] = useState({});

	const [update_surgery_access, set_update_surgery_access] = useState(true);

	useEffect(() => {
		set_initial_vital_signs({ ...vital_signs });
		set_initial_wound_inputs({ ...wound_inputs });
		set_initial_well_being({ ...well_being });
	}, []);

	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useForm();

	const ITEM_HEIGHT = 48;
	const ITEM_PADDING_TOP = 8;
	const MenuProps = {
		PaperProps: {
			style: {
				maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
				width: 250
			}
		}
	};

	const prevMedicationColumns = [
		{ field: 'ma_id', headerName: getLangString('id'), width: 40 },
		{
			field: 'medication_name',
			headerName: getLangString('medication_name'),
			flex: 1,
			minWidth: 100
		},
		{ field: 'medication_dose', headerName: getLangString('dose'), width: 130 },
		{
			field: 'ma_standard_frequency',
			headerName: getLangString('standard_frequency'),
			type: 'number',
			flex: 1,
			minWidth: 50
		},
		{
			field: 'activation',
			headerName: getLangString('status'),
			flex: 1,
			minWidth: 100,
			renderCell: (params) => (
				<SwitchButton params={params} onSubmit={handlePreviousMedicationChange} />
			)
		},
		{
			field: 'ma_start_date',
			headerName: getLangString('start_date'),
			type: 'date',
			flex: 1,
			minWidth: 100,
			valueGetter: (params) => {
				return params?.value?.split('T')[0];
			}
		},
		{
			field: 'ma_end_date',
			headerName: getLangString('end_date'),
			type: 'date',
			flex: 1,
			minWidth: 100,
			valueGetter: (params) => {
				return params?.value?.split('T')[0];
			}
		}
	];

	const newMedicationColumns = [
		{ field: 'ma_id', headerName: getLangString('id'), width: 40 },
		{
			field: 'medication_name',
			headerName: getLangString('medication_name'),
			flex: 1,
			minWidth: 100
		},
		{ field: 'medication_dose', headerName: getLangString('dose'), width: 130 },
		{
			field: 'ma_standard_frequency',
			headerName: getLangString('standard_frequency'),
			type: 'number',
			flex: 1,
			minWidth: 50
		},
		{
			field: 'ma_start_date',
			headerName: getLangString('start_date'),
			type: 'date',
			flex: 1,
			minWidth: 100,
			valueGetter: (params) => {
				return params?.value?.split('T')[0];
			}
		},
		{
			field: 'ma_end_date',
			headerName: getLangString('end_date'),
			type: 'date',
			flex: 1,
			minWidth: 100,
			valueGetter: (params) => {
				return params?.value?.split('T')[0];
			}
		},
		{
			field: 'actions',
			headerName: getLangString('action'),
			type: 'actions',
			minWidth: 120,
			getActions: (params) => [
				<GridActionsCellItem
					icon={<ModeEditOutlineOutlinedIcon />}
					label="Edit"
					onClick={editUser(params)}
				/>,
				<GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={deleteUser(params)} />
			]
		}
	];

	const setKpisStates = (kpis) => {
		kpis.forEach((item) => {
			const key = item.pm_metric_name;
			if (vital_signs.hasOwnProperty(key)) {
				const obj = (vital_signs) => ({
					...vital_signs,
					[key]: {
						...vital_signs[key],
						enable: item.pm_metric_enabled.toString(),
						frequency: item.pm_metric_frequency.toString(),
						start_date: item.pm_monitor_start.split('T')[0],
						end_date: item.pm_monitor_end.split('T')[0]
					}
				});
				set_vital_signs(obj);
				set_initial_vital_signs(obj);
			} else if (wound_inputs.hasOwnProperty(key)) {
				const obj = (wound_inputs) => ({
					...wound_inputs,
					[key]: {
						...wound_inputs[key],
						enable: item.pm_metric_enabled.toString(),
						frequency: item.pm_metric_frequency.toString(),
						start_date: item.pm_monitor_start.split('T')[0],
						end_date: item.pm_monitor_end.split('T')[0]
					}
				});
				set_wound_inputs(obj);
				set_initial_wound_inputs(obj);
			} else if (well_being.hasOwnProperty(key)) {
				const obj = (well_being) => ({
					...well_being,
					[key]: {
						...well_being[key],
						enable: item.pm_metric_enabled.toString(),
						frequency: item.pm_metric_frequency.toString(),
						start_date: item.pm_monitor_start.split('T')[0],
						end_date: item.pm_monitor_end.split('T')[0]
					}
				});
				set_well_being(obj);
				set_initial_well_being(obj);
			}
		});
	};

	const handlePreviousMedicationChange = (obj) => {
		set_previous_medications_obj((prevState) =>
			prevState.map((arrayObject) => {
				if (arrayObject.ma_id === obj.ma_id) {
					return { ...arrayObject, ma_is_active: obj.ma_is_active };
				}
				return arrayObject;
			})
		);
	};

	const deleteUser = (params) => () => {
		set_new_medication_obj(
			_.filter(new_medication_obj, function (o) {
				return o.med_id !== params.id;
			})
		);
	};

	const editUser = (params) => () => {
		set_new_medication_obj(
			_.filter(new_medication_obj, function (o) {
				return o.med_id !== params.id;
			})
		);
		set_add_medication_id(params.row.med_id);
		set_add_medication_name(params.row.medication_name);
		set_add_medication_dose(params.row.medication_dose);
		set_add_medication_daily_intake(params.row.ma_standard_frequency);
		set_add_medication_start_date(params.row.ma_start_date);
		set_add_medication_end_date(params.row.ma_end_date.split('T')[0]);
	};

	const setNewMedication = () => {
		if (
			add_medication_id === '' ||
			add_medication_name === '' ||
			add_medication_dose === '' ||
			add_medication_daily_intake === '' ||
			add_medication_start_date === '' ||
			add_medication_end_date === ''
		) {
			alertRef.current.handleClick({
				message: 'ALL MEDICATION FIELDS ARE REQUIRED',
				severity: 'error'
			});
			return;
		}
		let medication = {
			med_id: add_medication_id,
			medication_name: add_medication_name,
			medication_dose: add_medication_dose,
			ma_standard_frequency: add_medication_daily_intake,
			ma_start_date: add_medication_start_date,
			ma_end_date: add_medication_end_date.concat('T23:59:59')
		};
		set_new_medication_obj((oldArray) => [...oldArray, medication]);
		set_selected_medication_option(null);
		set_add_medication_id('');
		set_add_medication_name(null);
		set_add_medication_dose('');
		set_add_medication_daily_intake('');
		set_add_medication_start_date('');
		set_add_medication_end_date('');
	};

	const checkCPTCode = () => {
		if (procedure_code === '' || procedure_code === null || procedure_code_obj === undefined) {
			alertRef.current.handleClick({
				message: 'CPT Code is required',
				severity: 'error'
			});
			return false;
		}
		return true;
	};

	const compareKpis = (initial, updated) => {
		// compare initial with respect to updated
		// case 1: kpi no change donot send it
		// case 2: kpi was enable and now is disabled send it
		// case 3: new kpi is set
		// case 4: if whole kpi is disabled

		const keys = Object.keys(initial);

		let newObj = {};

		for (let key of keys) {
			if (key === 'enable_all') continue;
			// Compare the properties of initial and updated
			if (!_.isEqual(initial[key], updated[key])) {
				// Objects are not equal
				if (initial[key].enable === '1' && updated[key].enable === '0')
					// case 2
					newObj = { ...newObj, [key]: updated[key] };
				else if (updated[key].enable === '1') newObj = { ...newObj, [key]: updated[key] };
			}
		}

		return newObj;
	};

	const disableKpi = (initial) => {
		// compare initial with respect to updated
		const keys = Object.keys(initial);
		let newObj = {};
		for (let key of keys) {
			if (initial[key].enable === '1')
				newObj = { ...newObj, [key]: { ...initial[key], enable: '0', frequency: '0' } };
		}
		return newObj;
	};

	useEffect(() => {
		// check if user has access to update surgery
		if (isAdmin || isOrgAdmin) set_update_surgery_access(true);
		else {
			procedure_surgeons_list.find((item) => {
				if (profile?.profile?.profile_id === item.pdt_doctor_id) set_update_surgery_access(true);
				else set_update_surgery_access(false);
			});
		}
	}, [procedure_surgeons_list]);

	useEffect(() => {
		const fetchData = async () => {
			const urlParams = { resource: 'procedure', action: 'getmedications' };
			const data = await getListPost(urlParams);
			set_medications_list(data.data);

			set_update_medications(false);
		};
		fetchData().catch(console.error);
	}, [update_medications]);

	useEffect(() => {
		set_procedure_code_obj(all_cpt_codes.find((obj) => obj.cpt_code === data?.procedure_code));
	}, [all_cpt_codes]);

	const fetchCPTCodes = async (CPTCodeId) => {
		const urlParams = { resource: 'procedure', action: 'getCPTcode' };
		let data = await post(urlParams);
		set_all_cpt_codes(data.data);
		if (CPTCodeId) {
			const selected = data.data.find((c) => c.id === CPTCodeId);
			console.log('selected', selected);
			set_procedure_code_obj(selected);
			set_procedure_code(selected?.cpt_code);
			set_procedure_description(selected?.cpt_code_description);
		}
	};

	useEffect(() => {
		const fetchData = async () => {
			const procedure_id = JSON.parse(location.state.params.procedure_id);

			await fetchCPTCodes();

			const urlProcedureParams = { resource: 'procedure', action: 'getProcedureTreatmentPlan' };
			const pageData = await getListPost(urlProcedureParams, { procedure_id });
			// set_medications_list(pageData.data.medicationsResult);
			set_previous_medications_obj(pageData.data.procedureMedicationPlanResult);
			set_procedure_surgeons_list(pageData.data.procedureSurgeonsResult);
			set_procedure_doctors_roles(() => {
				let result = [];
				_.forEach(pageData.data.procedureSurgeonsResult, function (value) {
					result.push({
						doctor_id: value.pdt_doctor_id,
						interaction_type: value.pdt_interaction_type
					});
				});
				return result;
			});
			set_main_surgeon(() => {
				let mainSurgeon = _.find(pageData.data.procedureSurgeonsResult, [
					'pdt_interaction_type',
					'main'
				]);
				return {
					doctor_id: mainSurgeon?.pdt_doctor_id,
					interaction_type: mainSurgeon?.pdt_interaction_type
				};
			});
			setKpisStates(pageData.data.procedureTreatmentPlanResult);

			const urlUserParams = { resource: 'user', action: 'getDoctorsByClinic' };
			const params = { clinic_id: tokenData?.clinic_id ? tokenData.clinic_id : patient_clinic_id };
			const doctorsData = await getListPost(urlUserParams, params);
			doctorsData?.data?.sort((a, b) => a?.doctor_fullName.localeCompare(b?.doctor_fullName));
			set_clinic_surgeons_list(doctorsData.data);

			set_loading(false);
		};
		fetchData().catch(console.error);
	}, []);

	const GroupHeader = styled('div')(({ theme }) => ({
		position: 'sticky',
		top: '-8px',
		padding: '4px 10px',
		fontWeight: 'bold',
		fontSize: '18px',
		backgroundColor:
			theme.palette.mode === 'light'
				? lighten(theme.palette.primary.light, 1)
				: darken(theme.palette.primary.main, 1)
	}));

	const GroupItems = styled('ul')({
		padding: 0
	});

	useEffect(() => {
		set_selected_staff_doctors_names(
			_.map(
				_.intersectionWith(clinic_surgeons_list, procedure_surgeons_list, (arrVal, othVal) => {
					return (
						arrVal.doctor_id === othVal.pdt_doctor_id && othVal.pdt_interaction_type !== 'main'
					);
				}),
				function (value) {
					return value.doctor_fullName;
				}
			)
		);
	}, [clinic_surgeons_list]);

	useEffect(() => {
		if (vitalSignsEnabled > 0) set_check_vital_signs(true);
		else set_check_vital_signs(false);
	}, [vitalSignsEnabled]);

	useEffect(() => {
		if (woundInputsEnabled > 0) set_check_wound_inputs(true);
		else set_check_wound_inputs(false);
	}, [woundInputsEnabled]);

	useEffect(() => {
		if (wellBeingEnabled > 0) set_check_well_being(true);
		else set_check_well_being(false);
	}, [wellBeingEnabled]);

	useEffect(() => {
		const staffDoctors = selected_staff_doctors_names.map((staffDoctor) => ({
			doctor_id: staffDoctor.doctor_id,
			interaction_type: 'staff'
		}));

		const combinedDoctorsRoles = main_surgeon ? [main_surgeon, ...staffDoctors] : staffDoctors;

		set_procedure_doctors_roles(combinedDoctorsRoles);
	}, [main_surgeon, selected_staff_doctors_names]);

	useEffect(() => {
		if (_.some(['lip', 'cleft'], (el) => _.includes(procedure_description?.toLowerCase(), el)))
			set_procedure_model_type('face');
		else set_procedure_model_type('wound');
	}, [procedure_description]);

	const values = {
		procedure_id,
		procedure_is_active: procedure_is_active ? 1 : 0,
		procedure_patient_id,
		procedure_date_time,
		procedure_model_type,
		procedure_type,
		procedure_code,
		procedure_description,
		procedure_doctors_roles,
		previous_medications: previous_medications_obj,
		procedure_treatment_plan: {
			...(check_vital_signs
				? compareKpis(initial_vital_signs, vital_signs)
				: disableKpi(initial_vital_signs)),
			...(check_wound_inputs
				? compareKpis(initial_wound_inputs, wound_inputs)
				: disableKpi(initial_wound_inputs)),
			...(check_vital_signs
				? compareKpis(initial_well_being, well_being)
				: disableKpi(initial_well_being)),
			medication_obj: new_medication_obj
		},
		/////////// for testing
		medications_list,
		main_surgeon
	};

	const updateKpi = (kpi_type, key, values) => {
		kpi_type === 'vital_signs'
			? set_vital_signs((prevState) => ({
					...prevState,
					[key]: {
						...prevState[key],
						...values
					}
			  }))
			: kpi_type === 'wound_inputs'
			? set_wound_inputs((prevState) => ({
					...prevState,
					[key]: {
						...prevState[key],
						...values
					}
			  }))
			: set_well_being((prevState) => ({
					...prevState,
					[key]: {
						...prevState[key],
						...values
					}
			  }));
	};

	const onSubmit = async () => {
		try {
			if (!checkCPTCode()) return;
			if (!update_surgery_access) return;
			const urlParams = { resource: 'procedure', action: 'updateProcedure' };
			const response = await create(urlParams, values);
			if (response.status === 200)
				alertRef.current.handleClick({
					message: response.data.data.toUpperCase(),
					severity: 'success'
				});
		} catch (err) {
			alertRef.current.handleClick({
				message: err !== '' ? err.toString().split(':')[1].toUpperCase() : 'Failed',
				severity: 'error'
			});
		}
	};

	const theme = createTheme();

	return loading ? (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'center',
				height: '100%',
				width: '100%'
			}}>
			<CircularProgress />
		</Box>
	) : (
		<ThemeProvider theme={theme}>
			<CustomizedAlertBar ref={alertRef} path={-1} />
			<AddMedicationDialog
				open={openMedicationDailog}
				setOpen={setOpenMedicationDailog}
				updateMedications={set_update_medications}
			/>
			<AddCptCodeDailog
				open={openCptCodeDailog}
				setOpen={setOpenCptCodeDailog}
				fetchCPTCodes={fetchCPTCodes}
			/>
			<KpisDialog
				open={open_kpis_dailog}
				setOpen={set_open_kpis_dailog}
				kpiType={kpi_type}
				initialKpis={
					kpi_type === 'vital_signs'
						? initial_vital_signs
						: kpi_type === 'wound_inputs'
						? initial_wound_inputs
						: kpi_type === 'well_being'
						? initial_well_being
						: {}
				}
				kpis={
					kpi_type === 'vital_signs'
						? vital_signs
						: kpi_type === 'wound_inputs'
						? wound_inputs
						: kpi_type === 'well_being'
						? well_being
						: {}
				}
				setKpis={
					kpi_type === 'vital_signs'
						? set_vital_signs
						: kpi_type === 'wound_inputs'
						? set_wound_inputs
						: kpi_type === 'well_being'
						? set_well_being
						: {}
				}
				updateKpis={updateKpi}
			/>
			<CssBaseline />
			<Container maxWidth="xl" sx={{ mt: 4 }}>
				<Paper
					variant="outlined"
					sx={{
						borderRadius: 2,
						boxShadow: 2,
						borderColor: '#1db14c'
					}}>
					<Stack
						direction="row"
						spacing={1}
						sx={{
							display: 'flex',
							justifyContent: 'space-between'
						}}>
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'flex-start'
							}}>
							<Toolbar>
								<Typography variant="h6" align="left" color="#1db14c" sx={{}}>
									{getLangString('patient_surgery_setup')}
								</Typography>
							</Toolbar>
						</Box>
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'flex-end',
								pr: 2
							}}>
							<Stack spacing={1} direction="row" alignItems="center" sx={{ flexShrink: 0 }}>
								<Typography sx={{ fontSize: 14 }}>{getLangString('deactivate')}</Typography>
								<FormControlLabel
									control={
										<IOSSwitch
											checked={procedure_is_active}
											onChange={(event) => {
												set_procedure_is_active(event.target.checked);
											}}
											inputProps={{ 'aria-label': 'ant design' }}
										/>
									}
								/>
								<Typography sx={{ fontSize: 14 }}>{getLangString('activate')}</Typography>
							</Stack>
						</Box>
					</Stack>
				</Paper>
			</Container>
			{/* <form onSubmit={handleSubmit(onSubmit)}> */}
			<Container component="main" maxWidth="xl" sx={{ mb: 1 }}>
				<Paper
					variant="outlined"
					sx={{
						borderRadius: 2,
						boxShadow: 2,
						borderColor: '#1db14c',
						my: { xs: 3, md: 2 },
						p: { xs: 2, md: 3 }
					}}>
					<React.Fragment>
						<Stack direction={{ xs: 'column', sm: 'row' }} sx={{ mb: 2 }}>
							<Grid item xs={12} xl={6}>
								<Typography variant="h6" gutterBottom>
									{getLangString('surgery_details')}
								</Typography>
							</Grid>
						</Stack>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={4}>
								<FormControl fullWidth required>
									<InputLabel id="demo-multiple-checkbox-label">
										{getLangString('surgery_type')}
									</InputLabel>
									<Select
										id="surgeryType"
										name="surgeryType"
										label={getLangString('surgery_type')}
										variant="outlined"
										fullWidth
										required
										value={procedure_type}
										onChange={(e) => {
											set_procedure_type(e.target.value);
										}}>
										{surgeryType.map((option) => (
											<MenuItem key={option.value} value={option.value}>
												{option.label}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={12} sm={4}>
								<TextField
									required
									id="surgeryDate"
									name="surgeryDate"
									label={getLangString('surgery_date')}
									type="date"
									// inputFormat="yyyy-mm-dd"
									value={procedure_date_time}
									fullWidth
									variant="outlined"
									onChange={(e) => {
										set_procedure_date_time(e.target.value);
									}}
									InputLabelProps={{
										shrink: true,
										required: true
									}}
								/>
							</Grid>
							<Grid item xs={12} sm={4}>
								<FormControl fullWidth>
									<InputLabel id="demo-multiple-checkbox-label">
										{getLangString('supporting_staff')}
									</InputLabel>
									<Select
										id="staffSurgeons"
										name="staffSurgeons"
										label={getLangString('supporting_staff')}
										variant="outlined"
										multiple
										fullWidth
										value={selected_staff_doctors_names.map((doc) => doc.doctor_id)}
										onChange={(e) => {
											const {
												target: { value }
											} = e;
											// Find the full doctor objects by their doctor_id
											const selectedDoctors = clinic_surgeons_list.filter((doctor) =>
												value.includes(doctor.doctor_id)
											);
											set_selected_staff_doctors_names(selectedDoctors); // Store the full doctor objects
										}}
										renderValue={(selected) =>
											selected
												.map((id) => {
													const doctor = clinic_surgeons_list.find((doc) => doc.doctor_id === id);
													return doctor ? doctor.doctor_fullName : ''; // Map doctor_id back to doctor_fullName
												})
												.join(', ')
										}
										// disabled={load_clinic_Array}
										MenuProps={MenuProps}>
										{_.filter(
											clinic_surgeons_list,
											(o) => o.doctor_id !== main_surgeon?.doctor_id
										).map((option) => (
											<MenuItem key={option.doctor_id} value={option.doctor_id}>
												<Checkbox
													checked={selected_staff_doctors_names.some(
														(doc) => doc.doctor_id === option.doctor_id
													)}
												/>
												<ListItemText primary={option.doctor_fullName} />
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={12} sm={3}>
								<Autocomplete
									fullWidth
									disablePortal
									id="procedureCode"
									name="procedureCode"
									options={all_cpt_codes}
									getOptionLabel={(option) => {
										// console.log('----> getOptionLabel option', option);
										return option.cpt_code;
									}}
									renderInput={(params) => (
										<TextField fullWidth {...params} label={getLangString('surgery_code')} />
									)}
									value={procedure_code_obj}
									isOptionEqualToValue={(option, value) => {
										// console.log('option value', option, value);
										return option?.id === value?.id;
									}}
									onChange={(event, newValue, reason) => {
										if (reason === 'clear') {
											set_procedure_code_obj(null);
											set_procedure_code('');
											set_procedure_description('');
										}
										console.log('newValue', newValue);
										set_procedure_code_obj(newValue);
										set_procedure_code(newValue.cpt_code);
										set_procedure_description(newValue.cpt_code_description);
									}}
									noOptionsText={
										<div>
											<MenuItem disabled>
												<Typography sx={{ fontSize: '16px', color: 'black' }}>
													No matches
												</Typography>
											</MenuItem>
											<MenuItem
												onClick={() => {
													setOpenCptCodeDailog(true);
												}}>
												{/* <Button
												variant="text"
												color="secondary"
												type="button"
												// startIcon={<AddIc fontSize="small" />}
												// onClick={() => setOpenModal(true)}
											> */}
												<Typography sx={{ fontSize: '16px', color: 'black' }}>
													{`+ ${getLangString('add_new')}`}
												</Typography>
												{/* </Button> */}
											</MenuItem>
										</div>
									}
								/>
							</Grid>
							<Grid item xs={12} sm={9}>
								<TextField
									required
									id="surgeryDescription "
									name="surgeryDescription"
									label="Surgery Description"
									value={procedure_description}
									fullWidth
									variant="outlined"
									onChange={(e) => {
										set_procedure_description(e.target.value);
									}}
								/>
							</Grid>
							{/* <Grid item xs={12} sm={3}>
									<FormControl fullWidth required>
										<InputLabel id="demo-multiple-checkbox-label">Surgery Model Type</InputLabel>
										<Select
											id="modelType"
											name="modelType"
											label="Surgery Model Type"
											variant="outlined"
											fullWidth
											disabled
											value={procedure_model_type}
											onChange={(e) => {
												// set_procedure_model_type(e.target.value);
											}}>
											{[
												{ label: 'Face', value: 'face' },
												{ label: 'Wound', value: 'wound' }
											].map((option) => (
												<MenuItem key={option.value} value={option.value}>
													{option.label}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid> */}
						</Grid>
					</React.Fragment>
				</Paper>
			</Container>

			<Container component="main" maxWidth="xl" sx={{ mb: 1 }}>
				<Paper
					variant="outlined"
					sx={{
						borderRadius: 2,
						boxShadow: 2,
						borderColor: '#1db14c',
						my: { xs: 3, md: 2 },
						p: { xs: 2, md: 3 }
					}}>
					<React.Fragment>
						<Grid item xs={12} xl={6}>
							<Typography variant="h6" gutterBottom>
								{getLangString('monitoring_plan')}
							</Typography>
						</Grid>

						<Grid container spacing={3}>
							{/* <Grid item xs={12} sm={6}>
									<FormControl fullWidth>
										<TextField
											required
											id="monitoringPeriod"
											name="monitoringPeriod"
											label={getLangString('monitoring_period_from')}
											type="date"
											// inputFormat="yyyy-mm-dd"
											value={monitor_period_from}
											onChange={(event) => {
												console.log(event.target.value);
												set_monitor_period_from(event.target.value);
											}}
											fullWidth
											InputLabelProps={{
												shrink: true,
												required: true
											}}
										/>
									</FormControl>
								</Grid>
								<Grid item xs={12} sm={6}>
									<FormControl fullWidth>
										<TextField
											required
											fullWidth
											id="monitoringPeriod"
											name="monitoringPeriod"
											label={getLangString('monitoring_period_to')}
											type="date"
											// inputFormat="yyyy-mm-dd"
											value={monitor_period_to}
											onChange={(event) => {
												console.log(event.target.value);
												set_monitor_period_to(event.target.value);
											}}
											InputLabelProps={{
												shrink: true,
												required: true
											}}
										/>
									</FormControl>
								</Grid> */}
							<Grid item container>
								<Grid
									item
									xs={12}
									md={2.5}
									container
									alignItems="flex-end"
									justifyContent="flex-start">
									<Stack direction="row" alignItems="center">
										<IOSSwitch
											checked={check_vital_signs}
											onChange={(event) => {
												set_check_vital_signs(event.target.checked);
												set_open_kpis_dailog(event.target.checked);
												set_kpi_type('vital_signs');
											}}
											inputProps={{ 'aria-label': 'controlled' }}
											sx={{ mr: 1 }}
										/>
										<Stack direction="column" alignItems="end">
											<Link
												component="button"
												variant="body2"
												onClick={() => {
													set_open_kpis_dailog(true);
													set_kpi_type('vital_signs');
												}}
												sx={{
													fontFamily: 'Roboto',
													fontSize: '16px',
													fontWeight: 400,
													lineHeight: '19px',
													textAlign: 'left',
													mt: 2
												}}
												noWrap>
												{getLangString('vital_signs')}
											</Link>
											{vitalSignsEnabled > 0 ? (
												<Typography
													sx={{
														width: '24px',
														height: '16px',
														fontFamily: 'Roboto',
														fontStyle: 'normal',
														fontWeight: 400,
														fontSize: '11px',
														lineHeight: '17px',
														letterSpacing: '0.106132px',
														color: 'rgba(0, 0, 0, 0.6)'
													}}>
													{/* {check_vital_signs &&
														`${vitalSignsEnabled}/${Object.values(vital_signs).length}`} */}
												</Typography>
											) : (
												<Typography
													sx={{
														width: '24px',
														height: '16px',
														fontFamily: 'Roboto',
														fontStyle: 'normal',
														fontWeight: 400,
														fontSize: '11px',
														lineHeight: '17px',
														letterSpacing: '0.106132px',
														color: 'rgba(0, 0, 0, 0.6)'
													}}>
													{` `}
												</Typography>
											)}
										</Stack>
									</Stack>
								</Grid>
								<Grid
									item
									xs={12}
									md={2.5}
									container
									alignItems="flex-end"
									justifyContent="flex-start">
									<Stack direction="row" alignItems="center">
										<IOSSwitch
											checked={check_wound_inputs}
											onChange={(event) => {
												// set_check_wound_inputs(event.target.checked);
												set_open_kpis_dailog(event.target.checked);
												set_kpi_type('wound_inputs');
											}}
											inputProps={{ 'aria-label': 'controlled' }}
											sx={{ mr: 1 }}
										/>
										<Stack direction="column" alignItems="end">
											<Link
												component="button"
												variant="body2"
												noWrap
												onClick={(event) => {
													set_open_kpis_dailog(true);
													set_kpi_type('wound_inputs');
												}}
												sx={{
													fontFamily: 'Roboto',
													fontSize: '16px',
													fontWeight: 400,
													lineHeight: '19px',
													textAlign: 'left',
													mt: 2
												}}>
												{getLangString('wound_inputs')}
											</Link>
											{woundInputsEnabled > 0 ? (
												<Typography
													sx={{
														width: '24px',
														height: '16px',
														fontFamily: 'Roboto',
														fontStyle: 'normal',
														fontWeight: 400,
														fontSize: '11px',
														lineHeight: '17px',
														letterSpacing: '0.106132px',
														color: 'rgba(0, 0, 0, 0.6)'
													}}>
													{/* {check_wound_inputs &&
														`${woundInputsEnabled}/${Object.values(wound_inputs).length}`} */}
												</Typography>
											) : (
												<Typography
													sx={{
														width: '24px',
														height: '16px',
														fontFamily: 'Roboto',
														fontStyle: 'normal',
														fontWeight: 400,
														fontSize: '11px',
														lineHeight: '17px',
														letterSpacing: '0.106132px',
														color: 'rgba(0, 0, 0, 0.6)'
													}}>
													{` `}
												</Typography>
											)}
										</Stack>
									</Stack>
								</Grid>
								<Grid
									item
									xs={12}
									md={2.5}
									container
									alignItems="flex-end"
									justifyContent="flex-start">
									<Stack direction="row" alignItems="center">
										<IOSSwitch
											checked={check_well_being}
											onChange={(event) => {
												set_check_well_being(event.target.checked);
												set_open_kpis_dailog(event.target.checked);
												set_kpi_type('well_being');
											}}
											inputProps={{ 'aria-label': 'controlled' }}
											sx={{ mr: 1 }}
										/>
										<Stack direction="column" alignItems="end">
											<Link
												component="button"
												variant="body2"
												onClick={() => {
													set_open_kpis_dailog(true);
													set_kpi_type('well_being');
												}}
												sx={{
													fontFamily: 'Roboto',
													fontSize: '16px',
													fontWeight: 400,
													lineHeight: '19px',
													textAlign: 'left',
													mt: 2
												}}>
												{getLangString('well_being')}
											</Link>
											{wellBeingEnabled > 0 ? (
												<Typography
													disabled={wellBeingEnabled > 0}
													sx={{
														width: '24px',
														height: '16px',
														fontFamily: 'Roboto',
														fontStyle: 'normal',
														fontWeight: 400,
														fontSize: '11px',
														lineHeight: '17px',
														letterSpacing: '0.106132px',
														color: 'rgba(0, 0, 0, 0.6)'
													}}>
													{/* {check_well_being &&
														`${wellBeingEnabled}/${Object.values(well_being).length}`} */}
												</Typography>
											) : (
												<Typography
													sx={{
														width: '24px',
														height: '16px',
														fontFamily: 'Roboto',
														fontStyle: 'normal',
														fontWeight: 400,
														fontSize: '11px',
														lineHeight: '17px',
														letterSpacing: '0.106132px',
														color: 'rgba(0, 0, 0, 0.6)'
													}}>
													{` `}
												</Typography>
											)}
										</Stack>
									</Stack>
								</Grid>
							</Grid>
						</Grid>

						<Divider sx={{ my: 4 }} variant="middle" />

						<Grid container spacing={3}>
							<Grid item xs={12} xl={12}>
								<Typography variant="h6" gutterBottom>
									{getLangString('pervious_medications_treatment_plan')}
								</Typography>
							</Grid>
							<Box sx={{ mt: 3, mx: 2, height: 300, width: '100%' }}>
								<DataGrid
									columns={prevMedicationColumns}
									rows={previous_medications_obj}
									getRowId={(row) => row.ma_id}
									pageSize={5}
									rowsPerPageOptions={[5]}
									checkboxSelection
									disableSelectionOnClick
								/>
							</Box>
						</Grid>

						<Divider sx={{ my: 4 }} variant="middle" />

						<Typography variant="h6" sx={{ mb: 4 }} gutterBottom>
							{getLangString('prescription_medications')}
						</Typography>
						<Grid container spacing={3}>
							<Grid item xs={12} sm={12}>
								<Autocomplete
									fullWidth
									disablePortal
									id="medicationName"
									name="medicationName"
									options={medications_list?.sort((a, b) =>
										a.medication_group?.localeCompare(b.medication_group)
									)}
									groupBy={(option) => option.medication_group}
									renderGroup={(params) => (
										<li key={params.key}>
											<GroupHeader>{params.group}</GroupHeader>
											<GroupItems>{params.children}</GroupItems>
										</li>
									)}
									getOptionLabel={(option) =>
										`${option.medication_name} - ${option.medication_dose}`
									}
									renderInput={(params) => (
										<TextField fullWidth {...params} label={getLangString('medication_name')} />
									)}
									noOptionsText={
										<div>
											<MenuItem disabled>
												<Typography sx={{ fontSize: '16px', color: 'black' }}>
													No matches
												</Typography>
											</MenuItem>
											<MenuItem
												onClick={() => {
													setOpenMedicationDailog(true);
												}}>
												{/* <Button
													variant="text"
													color="secondary"
													type="button"
													// startIcon={<AddIc fontSize="small" />}
													// onClick={() => setOpenModal(true)}
												> */}
												<Typography sx={{ fontSize: '16px', color: 'black' }}>
													{`+ ${getLangString('add_new_medication')}`}
												</Typography>
												{/* </Button> */}
											</MenuItem>
										</div>
									}
									value={selected_medication_option}
									isOptionEqualToValue={(option, value) => option?.id === value?.id}
									onChange={(event, newValue, reason) => {
										if (reason === 'clear') {
											set_selected_medication_option(null);
											set_add_medication_name(null);
											set_add_medication_id('');
											set_add_medication_dose('');
										}
										if (!newValue) {
											set_add_medication_name(null);
											set_add_medication_dose('');
											return;
										}
										set_selected_medication_option(newValue);
										set_add_medication_name(newValue.medication_name);
										set_add_medication_id(newValue.medication_id);
										set_add_medication_dose(newValue.medication_dose);
									}}
								/>
								{/* <TextField
										select
										id="medicationName"
										name="medicationName"
										label={getLangString('medication_name')}
										fullWidth
										variant="outlined"
										// size="medium"
										value={add_medication_name}
										onChange={(event) => {
											set_add_medication_name(event.target.value);
											let obj = _.find(medications_list, {
												medication_name: event.target.value
											});
											set_add_medication_id(obj.medication_id);
											set_add_medication_dose(obj.medication_dose);
										}}>
										{medications_list.map((option) => {
											return (
												<MenuItem key={option.medication_id} value={option.medication_name}>
													{option.medication_name}
												</MenuItem>
											);
										})}
									</TextField> */}
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextField
									id="dose"
									name="dose"
									label={getLangString('dose')}
									fullWidth
									disabled
									variant="outlined"
									value={add_medication_dose}
									onChange={(event) => {
										set_add_medication_dose(event.target.value);
									}}
									// InputProps={{
									// 	endAdornment: <InputAdornment position="start">mg</InputAdornment>
									// }}
									InputLabelProps={{
										shrink: true
									}}>
									{' '}
								</TextField>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextField
									select
									id="dailyIntakeFrequency"
									name="dailyIntakeFrequency"
									label={getLangString('medication_standard_frequency')}
									fullWidth
									value={add_medication_daily_intake}
									variant="outlined"
									onChange={(event) => {
										set_add_medication_daily_intake(event.target.value);
									}}>
									{dailyIntakeFrequency.map((option) => (
										<MenuItem key={option.value} value={option.value}>
											{option.label}
										</MenuItem>
									))}
								</TextField>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextField
									fullWidth
									id="startDate"
									name="startDate"
									label={getLangString('start_date')}
									type="date"
									// inputFormat="yyyy-mm-dd"
									value={add_medication_start_date}
									onChange={(event) => {
										set_add_medication_start_date(event.target.value);
									}}
									InputLabelProps={{
										shrink: true
									}}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextField
									fullWidth
									id="endDate"
									name="endDate"
									label={getLangString('end_date')}
									type="date"
									// inputFormat="yyyy-mm-dd"
									value={add_medication_end_date}
									onChange={(event) => {
										set_add_medication_end_date(event.target.value);
									}}
									InputLabelProps={{
										shrink: true
									}}
								/>
							</Grid>
							<Grid item xs={12} xl={12}>
								<Box textAlign="left">
									<Button variant="contained" color="success" onClick={setNewMedication}>
										{getLangString('add')}
									</Button>
								</Box>
							</Grid>
							<Divider sx={{ my: 4 }} variant="middle" />

							<Grid item xs={12} xl={12}>
								<Typography variant="h6" gutterBottom>
									{getLangString('new_medications_treatment_plan')}
								</Typography>
							</Grid>
							<Box sx={{ mt: 4, mx: 2, height: 300, width: '100%' }}>
								<DataGrid
									columns={newMedicationColumns}
									rows={new_medication_obj}
									getRowId={(row) => row.med_id}
									pageSize={5}
									rowsPerPageOptions={[5]}
									checkboxSelection
									disableSelectionOnClick
								/>
							</Box>
						</Grid>

						<Grid container item xs={12} xl={12} mt={2} display="flex" spacing={0.5}>
							<Grid item sm={0} md={8.4} lg={9.6} xl={10}></Grid>

							<Grid item sm={12} md={1.8} lg={1.2} xl={1}>
								<Box textAlign="left">
									<Button
										variant="outlined"
										color="success"
										type="button"
										onClick={() => {
											navigate(-1);
										}}>
										{getLangString('cancel')}
									</Button>
								</Box>
							</Grid>
							<Grid item sm={12} md={1.8} lg={1.2} xl={1}>
								<Box textAlign="left">
									<Button
										variant="contained"
										color="success"
										type="submit"
										onClick={onSubmit}
										disabled={!update_surgery_access}>
										{getLangString('update')}
									</Button>
								</Box>
							</Grid>
						</Grid>
					</React.Fragment>
				</Paper>
			</Container>
			{/* </form> */}
		</ThemeProvider>
	);
};
const SwitchButton = (props) => {
	const { params, onSubmit } = props;

	return (
		<IOSSwitch
			checked={params.row.ma_is_active ? true : false}
			onChange={(event) => {
				params.row.ma_is_active = event.target.checked ? 1 : 0;
				onSubmit(params.row);
			}}
			inputProps={{ 'aria-label': 'ant design' }}
		/>
	);
};
export default EditSurgery;
